import { onDocumentReady } from "helpers"

class App {

  bindDomContentLoaded() {
    onDocumentReady(() => { this.bindFormAjaxError() })
  }

  bindTurboLinksEvents() {
    document.addEventListener("turbolinks:load", () => {
      //Autocomplete
      var input = $("#patient_autocomplete")
      var options = {
        getValue: "name",
        url: function(phrase) {
          return "/patients/search.json?q=" + phrase;
        },
        list: {
          maxNumberOfElements: 100,
          onChooseEvent: function() {
            var url = input.getSelectedItemData().url
            input.val("")
            Turbolinks.visit(url)
          }
        },
        minCharNumber: 3
      }
      input.easyAutocomplete(options)

      //multiple select
      var groupOne = [1,2,3,4,5,6,7,8,9]
      var groupTwo = [8,9,17,20,21,50]
      $("#routine_checkup").on('click',function(){
        $("#uncheck_all").prop("checked",false);
        groupOne.forEach(element => {
          $(".group_check_"+element).prop("checked",$(this).prop("checked"));
        })
      })
      $("#fever_profile").on('click',function(){
        $("#uncheck_all").prop("checked",false);
        groupTwo.forEach(element => {
          $(".group_check_"+element).prop("checked",$(this).prop("checked"));
        })
      })
      $(".investigate").on('click',function(){
        $("#uncheck_all").prop("checked",false);
      })
      $("#uncheck_all").on('click',function(){
        $(".investigate").prop("checked",false);
        $("#routine_checkup").prop("checked",false)
        $("#fever_profile").prop("checked",false)
      })
      //calculate BMI
      $('#appointment_height').on('blur',function(){
        var w = $('#appointment_weight').val(); 
        var h = $('#appointment_height').val();
        if(w && h){
          w = parseFloat(w); 
          h = parseFloat(h); 
          h = h/100;
          h = h*h;
          var bmi = w/h;
          $('#appointment_bmi').val(bmi.toFixed(2));
        }else{
         $('#appointment_bmi').val('');
        }
      });
      $('.dobpicker').on('keyup',function(){
        var inputVal = $(".dobpicker").val()
        if(inputVal.length >= 2){
          var current_date = new Date();
          var startYear = current_date.getFullYear() - inputVal;
          $('.dobpicker').val('01-01-'+startYear);
        }
      })
      $("#searchRange").on('click',function(){
        window.location = "/index?date="+$("#date-range-search").val()
      })
      $("#clear_results").on('click', function(e){
        e.preventDefault()
        $(".result_data").val("");
        return false;
      })

      $("#monthRange").on('click',function(){
        window.location = "/todays_collection?date="+$("#date-range-search").val()
      })

    })
    document.addEventListener("turbolinks:before-cache", () => {
      //Destroy plugins here
    })
  }

// Private
  bindFormAjaxError() {
    document.addEventListener("ajax:error", (e) => {
      if (e.target.matches("[data-remote='true']")) {
        iziToast.error({message: "Whoopps, something went wrong.", title: "Error", position: "topRight"})
      }
    })
  }
}

export function start() {
  const app = new App()
  app.bindDomContentLoaded()
  app.bindTurboLinksEvents()
}
