import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this.element.setAttribute("novalidate", "true")

    $(this.element).submit(function() {
      var form = $(this);

      if (form[0].checkValidity() === false) {
        form.addClass("was-validated");
        var errorElements = document.querySelectorAll(".form-control:invalid");
        for (let index = 0; index < errorElements.length; index++) {
          $('html, body').animate({
            scrollTop: $(errorElements[0]).focus().offset().top - 25
          }, 1000);
        }
        event.preventDefault();
        event.stopPropagation();
      } else {
        form.addClass("was-validated");
      }
    })

  }

}
