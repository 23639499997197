import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    const FORMAT = "DD/MM/YYYY hh:mm"

    this.element.placeholder = FORMAT

    $(this.element).daterangepicker({
      locale: { format: FORMAT },
      singleDatePicker: true,
      timePicker: true,
      autoUpdateInput: false
    })

    $(this.element).on("apply.daterangepicker", function(ev, picker) {
      $(this).val(picker.startDate.format(FORMAT))
    })

    $(this.element).on("cancel.daterangepicker", function(ev, picker) {
      $(this).val("")
    })
      
    //this.element.placeholder = "DD/MM/YYYY hh:mm"
    //new Cleave(this.element, { delimiters: ["/", "/", " ", ":"], blocks: [2, 2, 4, 2, 2] })
  }

}
