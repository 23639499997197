import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    const FORMAT = "DD/MM/YYYY"

    this.element.placeholder = FORMAT

    $(this.element).daterangepicker({
      locale: { format: FORMAT },
      singleDatePicker: true,
      autoUpdateInput: false
    })

    $(this.element).on("apply.daterangepicker", function(ev, picker) {
      $(this).val(picker.startDate.format(FORMAT))
    })

    $(this.element).on("cancel.daterangepicker", function(ev, picker) {
      $(this).val("")
    })
    this.element.placeholder = "YYYY/MM/DD"
    new Cleave(this.element, { date: true, datePattern: ["Y", "m", "d"] })
  }

}
